import React from "react";

const experienceContent = [
  {
    year: "Jun 2022 -  Continue",
    position: "Senior Software Engineer[Full Stack]",
    compnayName: "Mewoc Technologies",
    details: `IT Support & Services | Software Development | ecommerce Development | EdTech Solutions | ERP & CRM | Digital Branding`,
  },
  {
    year: "Aug 2018 -  Dec 2022",
    position: "Sr. Software Engineer(Team leader)",
    compnayName: "Unlocklive IT",
    details: `UnlockLive IT Limited provides state-of-the-art software solutions to clients all over the world.`,
  },
  {
    year: "Jul 2022 - Nov 2022",
    position: "Software Engineer (Remote)",
    compnayName: "Apex Property",
    details: `Apex Property is a Software Development Company.I am playing role for designing and developing software `,
  },
  {
    year: "Jan 2020 - Dec 2020",
    position: "Software Engineer (Remote)",
    compnayName: "Egenslab",
    details: `They are working product base. Basically working on wordpress plugin and theme development `,
  },
  {
    year: "Mar 2018 - Sep 2018",
    position: "Software Engineer",
    compnayName: "Brotherhood Infotech",
    details: `Brotherhood Infotech has a couple of furnished products. Also, we do Web & Mobile apps development and server hosting for our clients.`,
  },
  {
    year: "Jan 2018 - Mar 2018",
    position: "Internship",
    compnayName: "Olivine Limited",
    details: `OLIVINE Limited is the place that offers anything you want to automate for your business or individual purposes`,
  },
];

const Experience = () => {
  return (
    <ul>
      {experienceContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-briefcase"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.position}
            <span className="place open-sans-font">{val.compnayName}</span>
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Experience;
